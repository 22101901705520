document.addEventListener('DOMContentLoaded', () => {
  const body = document.getElementsByTagName('body')[0];
  const trigger = document.querySelector('.nav-trigger');
  const menu = document.querySelector('.navigation');
  const menuClose = document.querySelector('.navigation__button');

  trigger.addEventListener('click', () => {
    if (!body.classList.contains('homepage')) {
      menu.classList.add('is-open');
    }
  });

  menuClose.addEventListener('click', () => {
    if (!body.classList.contains('homepage')) {
      menu.classList.remove('is-open');
    }
  });
});
