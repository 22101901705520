/* eslint-disable no-plusplus */
/* eslint-disable func-names */
/* eslint-disable prefer-arrow-callback */

import { checkPeopleShrink } from './people';

document.addEventListener('DOMContentLoaded', () => {
  const body = document.getElementsByTagName('body')[0];
  const menu = document.querySelector('.navigation');

  const navigationElements = document.querySelectorAll('.js-navigation');
  const pageClasses = [];

  let initialPageClass = '';
  const initialPath = `${window.location.pathname}`;
  const initialTitle = document.querySelector('title').innerText;

  for (let i = 0; i < navigationElements.length; i++) {
    const pageClass = navigationElements[i].getAttribute('data-body-class');

    if (body.classList.contains(pageClass)) {
      initialPageClass = pageClass;
    }

    pageClasses.push(pageClass);
  }

  if (initialPageClass !== '') {
    window.history.replaceState(initialPageClass, initialTitle, initialPath);
  }

  function navigateToPage(pageClass) {
    let shrink = false;

    if (pageClass === 'people' && checkPeopleShrink('people', true)) {
      shrink = true;
    }

    if (pageClass === 'council' && checkPeopleShrink('council', true)) {
      shrink = true;
    }

    for (let i = 0; i < pageClasses.length; i++) {
      if (pageClasses[i] !== pageClass) {
        body.classList.remove(pageClasses[i]);
      }
    }

    body.classList.add(pageClass);

    // eslint-disable-next-line no-undef
    const routeData = routes;
    // eslint-disable-next-line no-undef
    const currentLocale = locale;

    const currentRouteData = routeData[pageClass];
    let title = 'Triangle';
    const pageTitle = currentRouteData.title[currentLocale];

    if (pageTitle !== '') {
      title = `${title} - ${pageTitle}`;
    }

    const newPath = currentRouteData.path[currentLocale];

    window.history.pushState(pageClass, title, newPath);

    document.querySelector('title').innerText = title;

    Object.keys(currentRouteData.path).forEach((language) => {
      if (language !== currentLocale) {
        const languageLink = document.getElementById(`language-switch-${language}`);

        languageLink.href = currentRouteData.path[language];
      }
    });

    if (shrink) {
      body.classList.add('is-shrunk');
    } else {
      body.classList.remove('is-shrunk');
    }

    if (!body.classList.contains('homepage')) {
      menu.classList.remove('is-open');
    }
  }

  window.addEventListener('popstate', (event) => {
    navigateToPage(event.state);
  });

  body.addEventListener('click', function () {
    if (this.classList.contains('homepage')) {
      navigateToPage('about');
    }
  });

  navigationElements.forEach((element) => {
    element.addEventListener('click', function (e) {
      if (!body.classList.contains('homepage')) {
        e.stopPropagation();
      }

      const pageClass = this.getAttribute('data-body-class');

      navigateToPage(pageClass);
    });
  });
});
