/* eslint-disable func-names */
/* eslint-disable no-param-reassign */

export default function validate() {}

export function checkPeopleShrink(pageClass, returnValue = false) {
  const body = document.getElementsByTagName('body')[0];
  const visiblePersons = document.querySelectorAll(`.content--${pageClass} .person.is-visible`);

  const shouldShrink = visiblePersons.length > 0;

  if (!returnValue) {
    if (shouldShrink) {
      body.classList.add('is-shrunk');
    } else {
      body.classList.remove('is-shrunk');
    }
  }

  return shouldShrink;
}

function isMobile() {
  const viewportWidth = window.innerWidth || document.documentElement.clientWidth;

  return viewportWidth < 768;
}

document.addEventListener('DOMContentLoaded', () => {
  const transitioningElements = [];
  // eslint-disable-next-line no-unused-vars
  let transitioningTimer;

  const checkTransitioningElements = function () {
    if (transitioningElements.length !== 0) {
      transitioningElements.forEach((element, index) => {
        const styleHeight = element.style.height;
        const currentHeight = `${element.offsetHeight}px`;

        if (styleHeight === '' || styleHeight === currentHeight) {
          transitioningElements.splice(index);
          element.style.height = '';
        }
      });

      transitioningTimer = setTimeout(checkTransitioningElements, 100);
    }
  };

  function addTransitioningElement(element) {
    transitioningElements.push(element);
    transitioningTimer = setTimeout(checkTransitioningElements, 100);
  }

  const persons = document.querySelectorAll('.person');

  persons.forEach((element) => {
    const name = element.querySelector('.people-list__name');

    name.addEventListener('click', function () {
      const personId = this.closest('.person').id;
      const currentPersons = this.closest('.people-list').querySelectorAll('.person');

      currentPersons.forEach((person) => {
        const desc = person.querySelector('.people-list__desc');

        if (person.id === personId && !person.classList.contains('is-visible')) {
          if (desc.style.height === '' || desc.style.height === '0px') {
            // show desc
            const descHeight = desc.scrollHeight;

            if (isMobile()) {
              desc.style.height = `${descHeight}px`;
            }

            person.classList.add('is-visible');

            addTransitioningElement(desc);
          }
        } else if (person.classList.contains('is-visible')) {
          // hide desc
          if (isMobile()) {
            desc.style.height = `${desc.offsetHeight}px`;
          }

          person.classList.remove('is-visible');

          addTransitioningElement(desc);
        }
      });

      const body = document.getElementsByTagName('body')[0];

      if (body.classList.contains('people')) {
        checkPeopleShrink('people');
      }

      if (body.classList.contains('council')) {
        checkPeopleShrink('council');
      }
    });
  });
});
